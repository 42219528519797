<template>
  <footer class="d-flex flex-column bg-secondary text-light">
    <div class="container-fluid bg-secondary text-muted text-center p-2">
      <small>Tous droits réservés ©mk-co {{ new Date().getFullYear() }}</small>
    </div>
  </footer>
</template>

<script>
export default {
  name: "TheFooterAdmin",
  components: {},
};
</script>
