<template>
  <section
    class="bg-secondary text-light p-3 d-flex flex-column justify-content-between h-100"
  >
    <div>
      <h2 class="text-primary">MENU</h2>
      <hr />
      <div>
        <ul class="navbar-nav text-lg-start lh-lg">
          <li class="nav-item text-lg-start">
            <router-link
              to="/admin/dashboard"
              class="nav-link d-flex align-items-end"
              ><div class="px-3"><IcoDashboard /></div>
              <div>Tableau de bord</div></router-link
            >
          </li>
          <li class="nav-item text-lg-start">
            <a
              class="nav-link d-flex align-items-end"
              data-bs-toggle="collapse"
              href="#collapseArticle"
              role="button"
              aria-expanded="false"
              aria-controls="collapseArticle"
            >
              <div class="px-3"><IcoArticle /></div>
              <div>Articles</div>
            </a>
            <div class="collapse ps-4" id="collapseArticle">
              <router-link
                to="/admin/nouvel-article"
                class="nav-link d-flex align-items-end"
                ><div class="px-3"><IcoPlus /></div>
                <div>Nouveau</div></router-link
              >
              <router-link
                to="/admin/liste-article"
                class="nav-link d-flex align-items-end"
                ><div class="px-3"><IcoList /></div>
                <div>Liste</div></router-link
              >
              <router-link
                to="/admin/categorie-article"
                class="nav-link d-flex align-items-end"
                ><div class="px-3"><IcoCategorie /></div>
                <div>Catégorie</div></router-link
              >
            </div>
          </li>
          <!-- <li class="nav-item text-lg-start">
            <router-link to="/" class="nav-link d-flex align-items-end"
              ><div class="px-3"><IcoClient /></div>
              <div>Clients</div></router-link
            >
          </li> -->
          <li class="nav-item text-lg-start">
            <a
              class="nav-link d-flex align-items-end"
              data-bs-toggle="collapse"
              href="#collapseOffre"
              role="button"
              aria-expanded="false"
              aria-controls="collapseOffre"
            >
              <div class="px-3"><IcoOffre /></div>
              <div>Offres d'emploi</div>
            </a>
            <div class="collapse ps-4" id="collapseOffre">
              <router-link
                to="/admin/nouvelle-offre"
                class="nav-link d-flex align-items-end"
                ><div class="px-3"><IcoPlus /></div>
                <div>Nouveau</div></router-link
              >
              <router-link
                to="/admin/liste-offre"
                class="nav-link d-flex align-items-end"
                ><div class="px-3"><IcoList /></div>
                <div>Liste</div></router-link
              >
              <router-link
                to="/admin/type-offre"
                class="nav-link d-flex align-items-end"
                ><div class="px-3"><IcoType /></div>
                <div>Type</div></router-link
              >
              <router-link
                to="/admin/categorie-offre"
                class="nav-link d-flex align-items-end"
                ><div class="px-3"><IcoCategorie /></div>
                <div>Catégorie</div></router-link
              >
            </div>
          </li>
          <li class="nav-item text-lg-start">
            <router-link
              to="/admin/candidat-service"
              class="nav-link d-flex align-items-end"
              ><div class="px-3"><IcoService /></div>
              <div>Services</div></router-link
            >
          </li>
          <li class="nav-item text-lg-start">
            <router-link
              to="/admin/accompagnement"
              class="nav-link d-flex align-items-end"
              ><div class="px-3"><IcoAccompagnement /></div>
              <div>Accompagnement</div></router-link
            >
          </li>
          <li class="nav-item text-lg-start" v-if="me.isSuperAdmin">
            <router-link
              to="/admin/candidat-mk"
              class="nav-link d-flex align-items-center"
              ><div class="px-3"><IcoSquare /></div>
              <div>Candidature spontanée</div></router-link
            >
          </li>
          <li class="nav-item text-lg-start">
            <router-link
              to="/admin/liste-newsletter"
              class="nav-link d-flex align-items-end"
              ><div class="px-3"><IcoNewletters /></div>
              <div>Newletters</div></router-link
            >
          </li>
          <li class="nav-item text-lg-start">
            <router-link
              to="/admin/liste-partenaire"
              class="nav-link d-flex align-items-end"
              ><div class="px-3"><IcoPartenaire /></div>
              <div>Partenaires</div></router-link
            >
          </li>
          <li class="nav-item text-lg-start">
            <router-link
              to="/admin/liste-reference"
              class="nav-link d-flex align-items-end"
              ><div class="px-3"><IcoPartenaire /></div>
              <div>Références</div>
            </router-link>
          </li>
          <li class="nav-item text-lg-start">
            <router-link
              to="/admin/liste-particularite"
              class="nav-link d-flex align-items-end"
              ><div class="px-3"><IcoParticularite /></div>
              <div>Particularités</div></router-link
            >
          </li>
          <li class="nav-item text-lg-start">
            <router-link
              to="/admin/question"
              class="nav-link d-flex align-items-end"
              ><div class="px-3"><IcoParticularite /></div>
              <div>Question FAQ</div></router-link
            >
          </li>
          <li class="nav-item text-lg-start" v-if="me.isSuperAdmin">
            <router-link
              to="/admin/collaborateur"
              class="nav-link d-flex align-items-end"
              ><div class="px-3"><IcoAccountGroup /></div>
              <div>Collaborateur</div></router-link
            >
          </li>
          <li class="nav-item text-lg-start">
            <router-link to="/" class="nav-link d-flex align-items-end"
              ><div class="px-3"><IcoLogout /></div>
              <div @click="disconnect">Se déconnecter</div></router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <div>
      <img src="../../assets/logo/MK-CO-TW.svg" alt="" srcset="" />
    </div>
    <div class="btnNotif d-none">
      <button class="btn btn-primary shadow rounded-circle text-dark p-2">
        <IcoBell />
      </button>
    </div>
  </section>
</template>

<script>
import IcoDashboard from "vue-material-design-icons/ViewDashboard.vue";
import IcoArticle from "vue-material-design-icons/Post.vue";
import IcoOffre from "vue-material-design-icons/BriefcaseSearch.vue";
import IcoService from "vue-material-design-icons/AccountCash.vue";
import IcoNewletters from "vue-material-design-icons/Mail.vue";
import IcoParticularite from "vue-material-design-icons/CardsVariant.vue";
import IcoAccountGroup from "vue-material-design-icons/AccountGroup.vue";
import IcoLogout from "vue-material-design-icons/LogoutVariant.vue";
import IcoPlus from "vue-material-design-icons/PlusCircle.vue";
import IcoList from "vue-material-design-icons/FormatListBulleted.vue";
import IcoType from "vue-material-design-icons/FormatListBulletedType.vue";
import IcoCategorie from "vue-material-design-icons/FormatListNumbered.vue";
import IcoBell from "vue-material-design-icons/Bell.vue";
import IcoSquare from "vue-material-design-icons/SquareOutline.vue";
import IcoPartenaire from "vue-material-design-icons/PlaylistMinus.vue";
import IcoAccompagnement from "vue-material-design-icons/HumanGreetingVariant.vue";
import { success } from "../../utils/toast";

export default {
  name: "TheSideAdmin",
  components: {
    IcoDashboard,
    IcoArticle,
    IcoOffre,
    IcoService,
    IcoNewletters,
    IcoParticularite,
    IcoLogout,
    IcoPlus,
    IcoList,
    IcoType,
    IcoCategorie,
    IcoBell,
    IcoSquare,
    IcoPartenaire,
    IcoAccountGroup,
    IcoAccompagnement,
  },
  computed: {
    me() {
      return this.$store.getters["collaborateurStore/meCollab"];
    },
  },
  mounted() {
    //console.log(this.me);
  },
  methods: {
    disconnect() {
      localStorage.removeItem("tokenCollab");
      this.$store.dispatch("collaborateurStore/setCollaborateur", {});
      this.$store.dispatch("collaborateurStore/setDisconnected");
      this.$router.push("/admin/se-connecter-admin");
      success("Vous êtes déconnecté");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";

section {
  a {
    color: $light;
    transition: 250ms;

    &:hover {
      color: darken($primary, $amount: 5);
    }

    &.router-link-exact-active {
      color: $primary;
    }
  }

  img {
    width: 100%;
  }
}

.btnNotif {
  position: fixed;
  bottom: 2rem;
  left: 2rem;
  transition: 250ms;
  z-index: 10;

  button {
    transition: 250ms;
  }

  &:hover button {
    animation: animation-vibrate 500ms alternate infinite;
  }
}
</style>
