<template>
  <div>
    <TheHeader />
    <div class="d-flex flex-lg-row flex-column">
      <section class="sub-1 order-lg-1 order-2">
        <TheSidenav />
      </section>
      <section class="sub-2 order-lg-2 order-1">
        <router-view />
      </section>
    </div>
    <TheFooter />
  </div>
</template>

<script>
import TheHeader from "../components/public/TheHeaderAdmin.vue";
import TheFooter from "../components/public/TheFooterAdmin.vue";
import TheSidenav from "../components/public/TheSideAdmin.vue";

export default {
  name: "LayoutAdmin",
  components: { TheHeader, TheFooter, TheSidenav },
};
</script>

<style lang="scss" scoped>
div {
  .sub-1 {
    flex: 2;
  }

  .sub-2 {
    flex: 10;
  }
}
</style>
