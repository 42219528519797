<template>
  <header
    :class="['container-fluid navbar sticky-top p-0 custom_transition', {}]"
  >
    <nav
      :class="[
        'navbar navbar-expand-lg navbar-dark bg-dark container-fluid custom_transition',
        {
          change_color: scrollPosition > 100,
        },
      ]"
    >
      <a class="navbar-brand d-lg-none d-block" href="#">
        <img
          src="../../assets/logo/MK-CO-2-W.svg"
          width="50"
          class="logo-MK-CO animation-show"
        />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navBarMK-CO"
        aria-controls="navBarMK-CO"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navBarMK-CO">
        <ul
          class="container-fluid navbar-nav text-lg-center d-flex align-items-lg-center align-items-end justify-content-evenly"
        >
          <li class="nav-item text-lg-center d-none d-lg-block">
            <a class="navbar-brand" href="#">
              <img
                src="../../assets/logo/MK-CO-2-W.svg"
                width="50"
                class="logo-MK-CO animation-show"
              />
            </a>
          </li>
          <li class="nav-item text-lg-center">
            <router-link to="/" class="nav-link">Découvrir</router-link>
          </li>
          <li class="nav-item text-lg-center">
            <router-link
              :to="`/accompagnement/${services[0] && services[0].slug}`"
              class="nav-link"
              >Accompagnement</router-link
            >
          </li>
          <li class="nav-item text-lg-center">
            <router-link to="/offre-emploi" class="nav-link"
              >Talents</router-link
            >
          </li>
          <li class="nav-item text-lg-center">
            <router-link to="/articles" class="nav-link"
              >S'informer</router-link
            >
          </li>

          <li class="dropdown text-end" v-click-outside="close">
            <button
              class="btn btn-primary rounded-circle position-relative btn-notif d-flex align-items-center justify-content-center"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              @click="toggleShow()"
            >
              <IcoNotif class="position-absolute" />
              <span
                v-if="totalUnread"
                class="position-absolute top-0 start-100 translate-middle border border-dark border-2 badge bg-warning rounded-pill"
              >
                {{ totalUnread }}
              </span>
            </button>
            <div
              v-if="show"
              class="notif dropdown-menu-end dropdown-w border-0 shadow p-0"
              aria-labelledby="dropdownMenuButton1"
            >
              <nav
                class="nav nav-pills flex-lg-row flex-column text-end bg-primary"
              >
                <div
                  class="flex-sm-fill text-sm-center tab-link py-3 fw-bold position-relative"
                  @click="showTab(1)"
                >
                  Commentaires
                  <span
                    class="ms-3 translate-middle badge bg-secondary position-absolute rounded-pill"
                  >
                    {{ commentUnread.length }}
                  </span>
                </div>
                <div
                  class="flex-sm-fill text-sm-center tab-link py-3 fw-bold position-relative"
                  @click="showTab(2)"
                >
                  Offres
                  <span
                    class="ms-3 translate-middle badge bg-secondary position-absolute rounded-pill"
                  >
                    {{ candidatOffreUntreated.length }}
                  </span>
                </div>
                <div
                  class="flex-sm-fill text-sm-center tab-link py-3 fw-bold position-relative"
                  @click="showTab(3)"
                >
                  Service
                  <span
                    class="ms-3 translate-middle badge bg-secondary position-absolute rounded-pill"
                  >
                    {{ candidatServiceUntreated.length }}
                  </span>
                </div>
                <div
                  class="flex-sm-fill text-sm-center tab-link py-3 fw-bold position-relative"
                  @click="showTab(4)"
                >
                  C. spontanée
                  <span
                    class="ms-3 translate-middle badge bg-secondary position-absolute rounded-pill"
                  >
                    {{ candidatMkUntreated.length }}
                  </span>
                </div>
              </nav>
              <div v-if="numberTab == 1">
                <a
                  class="text-start list-group-item border-0 list-notif border-top"
                  aria-current="true"
                  v-for="(comment, i) in commentUnread"
                  :key="i"
                  @click="navigateListComment(comment.id_article)"
                >
                  <div class="d-flex w-100 justify-content-between">
                    <h6 class="mb-1 text-primary">
                      {{
                        comment.first_name_visit + " " + comment.last_name_visit
                      }}
                      a commenté dans {{ comment.title_article }}
                    </h6>
                    <small class="date-notification">{{
                      moment(
                        new Date(comment.date_comment),
                        "YYYYMMDD"
                      ).fromNow(true)
                    }}</small>
                  </div>
                  <small class="mb-1">{{ comment.content_comment }}</small>
                  <br />
                  <!-- <small class="text-muted">And some small print.</small> -->
                </a>
                <div
                  v-if="!commentUnread.length"
                  class="text-center list-group-item border-0 list-notif text-white"
                >
                  Aucun nouveau commentaire
                </div>
              </div>
              <div v-if="numberTab == 2">
                <a
                  class="text-start list-group-item border-0 list-notif border-top"
                  aria-current="true"
                  v-for="(candidat, i) in candidatOffreUntreated"
                  :key="i"
                  @click="navigateListCandidatByOffre(candidat.id_offre)"
                >
                  <div class="d-flex w-100 justify-content-between">
                    <h6 class="mb-1 text-primary">
                      {{ candidat.full_name_candidat_offre }} a postulé pour "{{
                        candidat.title_offre
                      }}"
                    </h6>
                    <small class="date-notification">
                      {{
                        moment(new Date(candidat.date), "YYYYMMDD").fromNow(
                          true
                        )
                      }}</small
                    >
                  </div>
                  <!-- <small class="mb-1">
                    {{ candidat.message_candidat_offre }}
                  </small> -->
                  <br />
                  <!-- <small class="text-muted">And some small print.</small> -->
                </a>
                <div
                  v-if="!candidatOffreUntreated.length"
                  class="text-center list-group-item border-0 list-notif text-white"
                >
                  Aucun nouveau candidat
                </div>
              </div>
              <div v-if="numberTab == 3">
                <a
                  class="text-start list-group-item border-0 list-notif border-top"
                  aria-current="true"
                  v-for="(candidat, i) in candidatServiceUntreated"
                  :key="i"
                  @click="navigateListCandidatService()"
                >
                  <div class="d-flex w-100 justify-content-between">
                    <h6 class="mb-1 text-primary">
                      {{ candidat.fname_candidat_service }} a postulé dans
                      {{ candidat.type_service }}
                    </h6>
                    <small class="date-notification">{{
                      moment(new Date(candidat.date), "YYYYMMDD").fromNow(true)
                    }}</small>
                  </div>
                  <small class="mb-1">
                    {{ candidat.message_candidat_service }}
                  </small>
                  <br />
                  <small class="text-muted">
                    {{ candidat.type_candidat_service }}</small
                  >
                </a>
                <div
                  v-if="!candidatServiceUntreated.length"
                  class="text-center list-group-item border-0 list-notif text-white"
                >
                  Aucun nouveau candidat
                </div>
              </div>
              <div v-if="numberTab == 4">
                <a
                  class="text-start list-group-item border-0 list-notif border-top"
                  aria-current="true"
                  v-for="(candidat, i) in candidatMkUntreated"
                  :key="i"
                  @click="navigateListCandidatMK()"
                >
                  <div class="d-flex w-100 justify-content-between">
                    <h6 class="mb-1 text-primary">
                      {{ candidat.full_name_candidat_mk_co }} a postulé pour
                      nous rejoindre
                    </h6>
                    <small class="date-notification">{{
                      moment(new Date(candidat.date), "YYYYMMDD").fromNow(true)
                    }}</small>
                  </div>
                  <!-- <small class="mb-1">
                    {{ candidat.message_candidat_mk_co }}
                  </small> -->
                  <br />
                  <!-- <small class="text-muted"> lorem</small> -->
                </a>
                <div
                  v-if="!candidatMkUntreated.length"
                  class="text-center list-group-item border-0 list-notif text-white"
                >
                  Aucun nouveau candidat
                </div>
              </div>
            </div>
          </li>
          <li class="text-lg-center">
            <router-link to="/admin/me" class="nav-link">{{
              me.fname_collab
            }}</router-link>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
import IcoNotif from "vue-material-design-icons/Bell.vue";
import { socket } from "../../api/socket";
import { info } from "../../utils/toast";
import { getAllServicesWithoutContent } from "../../api/serviceAdmin";

import {
  getAllCandidatMkUntreated,
  getAllCandidatOffreUntreated,
  candidatServiceUntreated,
  getAllCommentUnread,
} from "../../api/notification";

export default {
  name: "TheHeaderAdmin",
  components: {
    IcoNotif,
  },
  data() {
    return {
      loading: true,
      candidatMkUntreated: [],
      candidatOffreUntreated: [],
      candidatServiceUntreated: [],
      commentUnread: [],
      numberTab: 1,
      show: false,
      scrollPosition: null,
      services: [],
    };
  },
  mounted() {
    getAllServicesWithoutContent().then((result) => {
      this.services = result.data;
    });
    this.fetch();
    socket.on("arrival-notif", (data) => {
      //console.log(data);
      if (data.type == "COMMENT") {
        info(`${data.data.first_name_visit} a commenté un article`);
      } else if (data.type == "OFFRE") {
        info(`${data.name} a postulé dans ${data.data.title_offre}`);
      } else if (data.type == "SERVICE") {
        info(`${data.name} a postulé dans ${data.service}`);
      } else if (data.type == "MK") {
        info(`${data.name} veut nous rejoindre`);
      }
      this.fetch();
    });
    socket.on("arrival-view", () => {
      this.fetch();
    });
  },
  computed: {
    totalUnread() {
      return (
        this.candidatMkUntreated.length +
        this.candidatOffreUntreated.length +
        this.candidatServiceUntreated.length +
        this.commentUnread.length
      );
    },
    me() {
      return this.$store.getters["collaborateurStore/meCollab"];
    },
  },
  methods: {
    close() {
      this.show = false;
    },
    fetch() {
      getAllCandidatMkUntreated().then((results) => {
        this.candidatMkUntreated = results.data;
        this.loading = false;
      });

      getAllCandidatOffreUntreated().then((results) => {
        this.candidatOffreUntreated = results.data;
      });

      candidatServiceUntreated().then((results) => {
        this.candidatServiceUntreated = results.data;
      });
      getAllCommentUnread().then((results) => {
        this.commentUnread = results.data;
      });
    },
    showTab(tab) {
      this.numberTab = tab; //fonction index tab
    },
    navigateListComment(param) {
      this.$router.push(`/admin/article/commentaire/${param}`).catch(() => {});
    },
    navigateListCandidatByOffre(param) {
      this.$router.push(`/admin/offre/candidat/${param}`).catch(() => {});
    },
    toggleShow() {
      this.show = !this.show;
    },
    navigateListCandidatService() {
      this.$router.push(`/admin/candidat-service/`).catch(() => {});
    },
    navigateListCandidatMK() {
      this.$router.push(`/admin/candidat-mk/`).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";

#navBarMK-CO a {
  color: $light;
  transition: 250ms;

  &:hover {
    color: darken($primary, $amount: 5);
  }

  &.router-link-exact-active {
    color: $primary;
  }
}

.notification {
  transition: 250ms;

  &:hover {
    color: $primary;
    transform: scale(1.2);
  }
}

.drop-menu-width {
  width: 30rem;
}

.list-notif {
  transition: 250ms;
  cursor: pointer;

  h6 {
    transition: 250ms;
  }

  &:nth-child(2n) {
    background-color: lighten($secondary, $amount: 5);
  }

  &:nth-child(2n + 1) {
    background-color: lighten($secondary, $amount: 10);
  }

  &:hover {
    background-color: $primary;

    h6 {
      color: $dark !important;
    }
  }
}
.admin-overflow {
  max-height: 90vh;
  overflow-y: scroll;
}
.dropdown-w {
  min-width: 35rem;
}

.tab-link {
  transition: 250ms;
  &:hover {
    background-color: lighten($secondary, $amount: 10);
    color: $primary;
    cursor: pointer;
  }
}
.list-notif {
  transition: 250ms;
  cursor: pointer;
  h6 {
    transition: 250ms;
  }
  &:nth-child(2n) {
    background-color: lighten($secondary, $amount: 5);
  }
  &:nth-child(2n + 1) {
    background-color: lighten($secondary, $amount: 10);
  }
  &:hover {
    background-color: lighten($primary, $amount: 35);
    color: $dark !important;
    h6 {
      color: $dark !important;
    }
  }
}
.notif {
  position: absolute;
  right: 1rem;
  min-width: 650px;
  max-height: 90vh;
  overflow-y: auto;
}

.btn-notif {
  padding: 1.15rem;
}
</style>
